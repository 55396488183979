declare global {
  interface Window {
    gtag: any;
  }
}

export type Event = {
  category: string;
  action: string;
  label?: string;
  value?: string;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url?: string) => {
  if (!window || !window.gtag) {
    return;
  }
  window.gtag('config', 'G-F13BKMRQ06', {
    page_path: url || window.location.href,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: Event) => {
  if (!window || !window.gtag) {
    return;
  }
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const consent = (granted: boolean) => {
  if (!window || !window.gtag) {
    return;
  }
  window.gtag('consent', 'update', {
    ad_storage: granted ? 'granted' : 'denied',
    analytics_storage: granted ? 'granted' : 'denied',
  });
};
