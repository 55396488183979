import type { AppProps } from 'next/app';

import { AuthProvider } from '@context/AuthContext';
import { PreferenceProvider } from '@context/PreferenceContext';

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <PreferenceProvider>
      <AuthProvider>
        <Component {...pageProps} />
      </AuthProvider>
    </PreferenceProvider>
  );
};

export default App;
