import { createContext, useEffect, useState } from 'react';

import { supabase } from '@utils/supabaseClient';
import { useContext } from 'react';

type SignInData = {
  email: string;
  password: string;
};

type AuthContext = {
  loading: boolean;
  logIn: (data: SignInData) => Promise<any>;
  logOut: () => Promise<any>;
  signUp: (data: SignInData) => Promise<any>;
  user: any;
};

type UserData = {
  id?: string;
  email?: string;
};

const Context = createContext<AuthContext>({
  loading: false,
  logIn: async () => {},
  logOut: async () => {},
  signUp: async () => {},
  user: null,
});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<UserData | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const session = supabase.auth.session();

    setUser(session?.user ?? null);
    setLoading(false);

    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setUser(session?.user ?? null);
        setLoading(false);
      }
    );

    return () => {
      listener?.unsubscribe();
    };
  }, []);

  const logIn = async (data: SignInData) => {
    return await supabase.auth.signIn(data);
  };

  const logOut = async () => {
    return await supabase.auth.signOut();
  };

  const signUp = async (data: SignInData) => {
    return await supabase.auth.signUp(data);
  };

  return (
    <Context.Provider value={{ loading, logIn, logOut, signUp, user }}>
      {children}
    </Context.Provider>
  );
};

const useAuth = () => useContext(Context);

export { AuthProvider, Context, useAuth };
