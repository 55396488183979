import { createContext, useEffect, useState } from 'react';

import { consent, pageview } from '@utils/gtag';

const locationService = 'https://cloudflare-quic.com/b/headers';

type Preferences = {
  necessary: boolean;
  functional: boolean;
  statistics: boolean;
};

interface Context {
  country: string;
  isCookieBarVisible: boolean;
  preferenceNecessary: boolean;
  preferenceFunctional: boolean;
  preferenceStatistics: boolean;
  changeCountry: (country: string) => void;
  changePreferences: ({
    necessary,
    functional,
    statistics,
  }: Preferences) => void;
  showCookieBar: () => void;
}

const Context = createContext<Context | undefined>(undefined);

const PreferenceProvider = ({ children }) => {
  const [isCookieBarVisible, setCookieBarVisible] = useState(false);
  const [preferenceNecessary, setPreferenceNecessary] = useState(false);
  const [preferenceFunctional, setPreferenceFunctional] = useState(false);
  const [preferenceStatistics, setPreferenceStatistics] = useState(false);

  const [country, setCountry] = useState('US');

  const setupAnalytics = (preferenceStatistics) => {
    consent(preferenceStatistics);

    // TODO: page view will double count if someone changes their cookie settings
    if (preferenceStatistics) {
      pageview();
    }
  };

  // set user preferences locally
  const setPreferences = ({
    necessary,
    functional,
    statistics,
  }: Preferences) => {
    setPreferenceNecessary(necessary);
    setPreferenceFunctional(functional);
    setPreferenceStatistics(statistics);
    setupAnalytics(statistics);

    setCookieBarVisible(false);
  };

  useEffect(() => {
    const storedNecessary: string | null = localStorage.getItem('necessary');
    const storedFunctional: string | null = localStorage.getItem('functional');
    const storedStatistics: string | null = localStorage.getItem('statistics');
    const storedCountry: string | null = localStorage.getItem('country');

    if (!storedNecessary) {
      setCookieBarVisible(true);
    } else {
      setPreferences({
        necessary: storedNecessary === 'true',
        functional: storedFunctional === 'true',
        statistics: storedStatistics === 'true',
      });
    }

    if (storedCountry) {
      setCountry(storedCountry);
    } else {
      try {
        fetch(locationService)
          .then((res) => res.json())
          .then((data) => {
            setCountry(data.headers['Cf-Ipcountry']);
          });
      } catch (error) {
        setCountry('US');
      }
    }
  }, []);

  // change preferences locally and in local storage
  const changePreferences = ({
    necessary,
    functional,
    statistics,
  }: Preferences) => {
    setPreferences({ necessary, functional, statistics });

    if (necessary) {
      localStorage.setItem('necessary', necessary.toString());
      localStorage.setItem('functional', functional.toString());
      localStorage.setItem('statistics', statistics.toString());

      if (functional) {
        localStorage.setItem('country', country);
      } else {
        localStorage.removeItem('country');
      }
    } else {
      localStorage.clear();
    }
  };

  const showCookieBar = () => {
    setCookieBarVisible(true);
  };

  const changeCountry = (country) => {
    if (country) {
      setCountry(country);

      if (preferenceFunctional) {
        localStorage.setItem('country', country);
      }
    }
  };

  return (
    <Context.Provider
      value={{
        country,
        changeCountry,
        isCookieBarVisible,
        preferenceNecessary,
        preferenceFunctional,
        preferenceStatistics,
        changePreferences,
        showCookieBar,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { PreferenceProvider, Context };
